import React, {useState} from 'react';

const WarningDemoModeConatiner = ({setVisisibility}) => {

    return (
        <div style={{
            background: '#ff000050',
            width: '90%',
            padding: '1em',
            margin: '0 auto 1em auto',
            border: '2px solid lightgrey',
            boxShadow: '4px 4px 30px -10px grey'
        }}
             onClick={() => setVisisibility(false)}
        >
            <p style={{
                textAlign: 'right'
            }}>X</p>
            <p>Datos demostrativos.</p>
            <p>No se puede interactuar con esta sección porque la base de datos no está activa en esta DEMO.</p>
        </div>
    )
}
export default WarningDemoModeConatiner;