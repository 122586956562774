import React from 'react';

const Socialpymes = () => {

  return (
    <div>
      <div>
        <span>powered by</span>
      </div>
      <img  src="./assets/img/socialPymes_Imagotipo.png" alt="Logo de Socialpymes"/>
    </div>
  )
}

export default Socialpymes;
