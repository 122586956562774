import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import "babel-polyfill";
/*
*
* IMPORT STORE REDUX
*
* */
import store from './redux/store';
import {Provider} from "react-redux";
/*
* IMPORT COMPONENTS ans containers
 */
import App from './containers/App';
import Launcher from './containers/Launcher';
import Error404 from './containers/Error404';
import Subcategoria from './containers/Subcategoria';
import ComandappHome from "./containers/homecomanda/ComandappHome";
/*
*
*  IMPORT ROUTER
*
* */
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
/*
*
*  IMPORT STYLES
*
* */
import './index.css';
import "./App.css"

/*
*
*  IMPORT VIEWS
*
* */
// import Header from './containers/Header'
// import Footer from './containers/Footer'

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            {/*<Header/>*/}
            <Switch>
                <Route path="/" exact component={Launcher}/>
                <Route path="/categoria" component={App}/>
                <Route path="/subcategoria" component={Subcategoria}/>
                <Route path="/comandappHome" component={ComandappHome}/>
                <Route path="/404" component={Error404}/>
                <Redirect to="/404"/>
            </Switch>
            {/* <Footer /> */}
            {/*<Switch>*/}
            {/*    <Route exact path="/">*/}
            {/*        <Launcher/>*/}
            {/*    </Route>*/}
            {/*    <Route path="/categoria">*/}
            {/*        <App/>*/}
            {/*    </Route>*/}
            {/*    <Route path="/subcategoria">*/}
            {/*        <Subcategoria/>*/}
            {/*    </Route>*/}
            {/*    <Route path="/comandappHome">*/}
            {/*        <ComandappHome/>*/}
            {/*    </Route>*/}
            {/*    <Route path="*">*/}
            {/*        <Error404/>*/}
            {/*    </Route>*/}
            {/*</Switch>*/}
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
