import React from 'react';

const Buttonsubmit = () => {
    return (
        <button
            type="submit"
            id="submit_login"
        >Guardar
        </button>
    )
}
export default Buttonsubmit;