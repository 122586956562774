import React, { Fragment } from "react";

const Spinner = () => {

    return (
        <Fragment>
            <div id="loadingProgressG">
                <div className="loadingProgressG"/>
            </div>
        </Fragment>
    )
};

export default Spinner;
